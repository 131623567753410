import { mapMutations, mapState } from 'vuex';

import { getItem } from '@/api/api-methods';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';

import KnTable from '../../shared/components/KnTable.vue';
import { canAdd } from '../../shared/helpers/permissionsUtils';

export default {
  mixins: [paginationMixin],
  components: { KnTable },
  data() {
    return {
      groups: [],
      products: [],
      headers: [
        { text: 'SKU', value: 'sku', class: 'purple--text' },
        { text: 'Nombre', value: 'nombre_producto', class: 'purple--text' },
        {
          text: 'Descripción',
          value: 'descripcion_corta',
          class: 'purple--text',
        },
        { text: 'Marca', value: 'marca.dato', class: 'purple--text' },
        { text: 'Precio', value: 'precio', class: 'purple--text' },
        { text: 'ID', value: 'id', class: 'purple--text' },
        { text: '', value: 'acciones', class: 'purple--text' },
      ],
      items: [],
      loading: false,
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'warehouse', 'search']),
  },
  watch: {
    search: {
      async handler() {
        if (this.loading) return;
        await this.delay(500);
        await this.getProducts();
      },
    },
    institutionId: {
      async handler() {
        await this.delay(500);
        await this.getProducts();
      },
    },
  },
  async created() {
    this.setIsLogin(false);
    await this.getProducts();
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    // openConf(id) {
    //   // console.log(id);
    // },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    canAdd: canAdd,
    async getProducts() {
      try {
        this.loading = true;
        const limitFilter =
          this.pagination.limit !== null
            ? `&limit=${this.pagination.limit}`
            : '';
        const offsetFilter =
          this.pagination.offset !== 0
            ? `&offset=${this.pagination.offset}`
            : '';
        const searchFilter =
          this.search !== null ? `&nombre_producto=${this.search}` : '';

        const url = `/app-productos/filters/product?institucion_educativa=${this.institutionId}&estatus_sistema=true${offsetFilter}${limitFilter}${searchFilter}`;
        const responseData = await getItem(url);
        this.items = responseData.results;
        this.setPaginationCount(responseData.count);
        await this.populateProducts();
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        this.loading = false;
      }
    },

    async populateProducts() {
      try {
        for (let i = 0; i < this.items.length; i++) {
          const product = this.items[i];
          const priceProduct =
            product.precios.find(
              (price) => price.distribuidor === this.institutionId
            ) || null;
          product.precio = priceProduct
            ? `$${priceProduct.precio_distribuidor}`
            : '$0.00';
        }
      } catch (error) {
        console.error('Error populating products:', error);
      }
    },

    async changePageProducts(page = 1) {
      this.setPaginationPage(page);
      await this.getProducts();
    },
  },
};
